export default [
  {
    title: 'Giriş',
    icon: 'HomeIcon',
    route: 'home',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Günlük Kayıtları',
    icon: 'ArchiveIcon',
    route: 'Logs',
    resource: 'LOGS',
    action: 'read',
  },
  {
    header: 'Kullanıcılar',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcı Listesi',
        route: 'Users',
        icon: 'UserIcon',
        resource: 'USERS_LIST',
        action: 'read',
      },
      {
        title: 'Kullanıcı Seviyeleri',
        route: 'UserTypes',
        icon: 'ShieldIcon',
        resource: 'USER_TYPES_LIST',
        action: 'read',
      },
    ],
  },
  {
    header: 'Ayarlar',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP Ayarları',
        route: 'Smtpconfig',
        icon: 'MailIcon',
        resource: 'SMTP_CONFIG',
        action: 'read',
      },
    ],
  },
]
